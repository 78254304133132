import * as React from "react";
import { Link } from "gatsby";
import { Container } from "react-bootstrap";
import Layout from "../components/Layout";
import "../scss/404.scss";

const NotFoundPage = () => {
  return (
    <Layout title="" description="">
      <Container as={"section"} className="ErrorPage">
        <div className="ErrorPage__Container">
          <h1 className="ErrorPage__Container__404">404</h1>
          <h2 className="ErrorPage__Container__Text ErrorPage__Container__Text__Sorry">
            sorry!
            <br />
            page not found.
          </h2>
          <h3 className="ErrorPage__Container__Text ErrorPage__Container__Text__PageExist">
            This page does not exist or was removed!
          </h3>
          <Link to="/" className="ErrorPage__Container__Button">
            Return to Wunderbar Bologna
          </Link>
        </div>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;

export const Head = () => <title>Not found</title>;
